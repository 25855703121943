export const environment = {
    production: false,
    apiUrl: {
        
        root: 'https://api-staging.kuppel.co',
        admin: 'https://api-staging.kuppel.co/admin',
        app: 'https://api-staging.kuppel.co/app',
        soporte: 'https://api-staging.kuppel.co/support'

    }
};

