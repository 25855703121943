import { Pipe, PipeTransform } from '@angular/core';
import * as moment from 'moment';

@Pipe({
    name: 'datetime'
})
export class DatetimePipe implements PipeTransform {
    transform(dateUnix: string | number, args?: any): any {
        return moment.unix(Number(dateUnix)).format('YYYY-MM-DD hh:mm:ss a')
    }
}
